import React, { Fragment } from 'react';

import { Timer } from 'components';
import { SHOW_PAYOUT_TIMER_WHEN_LESS } from 'constants/order.constants';
import { PayoutOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderDisputeColumn: React.FC<Props> = ({ order }) => (
  <Fragment>
    {order.statusDetails &&
      orderUtils.getPayoutStatusDetailsLabel(order.statusDetails)}
    <div>
      <Timer
        endTime={orderUtils.getMaxTimeoutAt(order)}
        showWhenLess={SHOW_PAYOUT_TIMER_WHEN_LESS}
        hideTimeOver
        sx={{ mt: 1 }}
      />
    </div>
  </Fragment>
);
